import {
  FILE_SHARE_MODE_SELECTED,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "@recare/core/consts";
import api from "@recare/core/model/api";
import { computeSealdDisplayId } from "@recare/core/seald";
import {
  addUsersToSession,
  removeUsersFromSession,
} from "@recare/core/seald/sessions";
import {
  FileParams,
  Filev2,
  QueryProgress,
  RadioOption,
} from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import {
  useDeleteSealdAccess,
  useUpdateFileMutation,
} from "apollo/hooks/mutations";
import { useGetFilesLazyQuery } from "apollo/hooks/queries";
import { useEnvContext } from "context/EnvContext";
import {
  GeneralListAction,
  SetAction,
} from "ds/components/Tables/GeneralTable/MenuActions";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { Dispatch, SetStateAction } from "react";
import { FileShareResultWithInitialValues } from "../../organisms/Modals/FileSharingModal";

function getUniqueIds(arr1: RadioOption[], arr2: RadioOption[]) {
  return arr1.reduce<number[]>((acc, curr) => {
    if (!arr2.some(({ id }) => curr.id === id)) {
      return [...acc, Number(curr.id)];
    }
    return acc;
  }, []);
}

export function useShareFile<Data>({
  action,
  params,
  setAction,
  setProgress,
}: {
  action: GeneralListAction<Filev2> | undefined;
  params: FileParams;
  setAction: SetAction<Data>;
  setProgress: Dispatch<SetStateAction<QueryProgress>>;
}) {
  const envContext = useEnvContext();
  const notify = useNotification();
  const translations = useTranslations();

  const [updateFile] = useUpdateFileMutation({
    id: action?.data.id ?? -1,
    params,
  });
  const [getFilesLazy] = useGetFilesLazyQuery({ params });

  const [deleteSealdAccess] = useDeleteSealdAccess();

  async function handleShareFile({
    initialValues,
    selected_receivers,
    share_mode,
  }: FileShareResultWithInitialValues) {
    try {
      setProgress(QUERY_PROGRESS_PENDING);

      const { data } = await updateFile({ share_mode });
      if (!data?.file) {
        throw Error("[handleShareFile] Couldn't update file");
      }

      const sessionId = data.file?.seald_encryption_context?.seald_id;

      const shouldUpdateSelectedCareproviders =
        share_mode === FILE_SHARE_MODE_SELECTED;

      const sealdPromises: Promise<void>[] = [];

      if (shouldUpdateSelectedCareproviders) {
        if (
          !initialValues.selected_receivers ||
          !selected_receivers ||
          !sessionId
        ) {
          throw new Error(
            "initialValues.selected_receivers, selected_receivers and sessionId must be defined",
          );
        }
        const removedCareproviderIds = getUniqueIds(
          initialValues.selected_receivers,
          selected_receivers,
        );
        const addedCareproviderIds = getUniqueIds(
          selected_receivers,
          initialValues.selected_receivers,
        );

        addedCareproviderIds.forEach((id) => {
          sealdPromises.push(
            addUsersToSession({
              sessionId,
              entityUsersDisplayIds: [
                computeSealdDisplayId({
                  id,
                  type: "careprovider",
                  envContext,
                }),
              ],
              createSealdAccess: api.crypto.createSealdAccess,
            }),
          );
        });

        removedCareproviderIds.forEach((id) => {
          sealdPromises.push(
            removeUsersFromSession({
              deleteSealdAccess,
              sessionId,
              entityUsersDisplayIds: [
                computeSealdDisplayId({
                  id,
                  type: "careprovider",
                  envContext,
                }),
              ],
            }),
          );
        });
      }

      await Promise.all(sealdPromises);

      await getFilesLazy();
      setProgress(QUERY_PROGRESS_SUCCEED);
      notify({
        message: translations.fileSection.fileShareComponent.toastSuccess,
        type: "success",
      });
      setAction(undefined);
    } catch (err) {
      setProgress(QUERY_PROGRESS_FAILED);
      notify({ message: translations.fileSection.toastError, type: "error" });
      console.log(err);
    }
  }

  return { handleShareFile };
}
