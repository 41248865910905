import {
  FILE_TABLE_ACTION_SYNC_VERSION,
  FILE_TABLE_ACTION_UPLOAD_AND_SHARE_HIS,
  FILE_TABLE_ACTION_UPLOAD_HIS,
  QUERY_PROGRESS_NOT_STARTED,
} from "@recare/core/consts";
import {
  AuctionRequest,
  FileHISStateful,
  FileParams,
  Filev2,
  Filev2Stateful,
  QueryProgress,
} from "@recare/core/types";
import { ProcessedFile } from "ds/components/FileUploader";
import { GeneralListAction } from "ds/components/Tables/GeneralTable/MenuActions";
import { useImportAndUploadFilesContentHIS } from "onprem/hooks";
import { useEffect, useState } from "react";
import { useDeleteFile } from "./useDeleteFile";
import { useDownloadFile } from "./useDownloadFile";
import { useShareFile } from "./useShareFile";
import { FileUploadStates, useUploadFile } from "./useUploadFile";

export type FileFeatureData =
  | FileHISStateful
  | (FileHISStateful & { fileV2Id?: Filev2Stateful["id"] })[]
  | Filev2
  | ProcessedFile;

export function useFilesFeatures({
  auctionRequest,
  externalId,
  params,
}: {
  auctionRequest?: AuctionRequest;
  externalId?: string;
  params: FileParams;
}) {
  const [uploadStates, setUploadStates] = useState<FileUploadStates>({});
  const [action, setAction] = useState<
    GeneralListAction<FileFeatureData> | undefined
  >();
  const [mutationProgress, setProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  const { handleUploadFile } = useUploadFile({
    auctionRequest,
    params,
    setAction,
    setUploadStates,
    uploadStates,
  });
  const { handleShareFile } = useShareFile({
    action: action as GeneralListAction<Filev2> | undefined,
    params,
    setAction,
    setProgress,
  });
  const { handleDeleteFile } = useDeleteFile({
    action: action as GeneralListAction<Filev2> | undefined,
    params,
    setAction,
    setProgress,
  });
  useDownloadFile({
    action: action as GeneralListAction<Filev2> | undefined,
    externalId: externalId || "",
    setAction,
    setProgress,
  });

  useImportAndUploadFilesContentHIS({
    action,
    externalId: externalId || "",
    filesHIS: action?.data as FileHISStateful[],
    skip:
      !externalId ||
      ![
        FILE_TABLE_ACTION_UPLOAD_HIS,
        FILE_TABLE_ACTION_UPLOAD_AND_SHARE_HIS,
        FILE_TABLE_ACTION_SYNC_VERSION,
      ].includes(action?.type as any) ||
      !action?.data,
    handleUploadFile,
    handleDeleteFile,
    setAction,
  });

  async function handleReset() {
    setAction(undefined);
    setProgress(QUERY_PROGRESS_NOT_STARTED);
  }

  useEffect(() => {
    if (action?.type) {
      setProgress(QUERY_PROGRESS_NOT_STARTED);
    }
  }, [action?.type]);

  return {
    action,
    handleDeleteFile,
    handleReset,
    handleShareFile,
    handleUploadFile,
    mutationProgress,
    setAction,
    setProgress,
    uploadStates,
    setUploadStates,
  };
}
