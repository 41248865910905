import { useMutation } from "@apollo/client";
import { DELETE_FILE, UPDATE_FILE } from "@recare/core/apollo/graphql";
import {
  FILE_CATEGORY_AND,
  FILE_SHARE_MODE_NONE,
  FILE_SHARE_MODE_SELECTED,
  FILE_TABLE_ACTION_SHARE,
  TRACK_EVENTS,
} from "@recare/core/consts";
import api from "@recare/core/model/api";
import { getErrorMessage } from "@recare/core/model/utils/errors";
import { computeSealdDisplayId } from "@recare/core/seald";
import { useSealdContext } from "@recare/core/seald/SealdContext";
import { encryptForSession } from "@recare/core/seald/sessions";
import { AuctionRequest, FileParams, Filev2 } from "@recare/core/types";
import {
  useCreateEncryptionContext,
  useCreateFileMutation,
} from "apollo/hooks/mutations";
import {
  useGetFilesLazyQuery,
  useGetSelectableCareprovidersLazy,
} from "apollo/hooks/queries";
import { useEnvContext } from "context/EnvContext";
import { differenceInMilliseconds, getTime } from "date-fns";
import { SetAction } from "ds/components/Tables/GeneralTable/MenuActions";
import { useAuctionRequestEventsContext } from "dsl/atoms/AuctionRequestEventsContext";
import {
  FileUploadSubmitHandler,
  UploadedFileResult,
} from "dsl/organisms/Modals/FileUploadModal";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTracking } from "react-tracking";
import {
  useLoggedCareprovider,
  useLoggedCareseeker,
} from "reduxentities/selectors/hooks";
import { v4 as uuid } from "uuid";
import { FileFeatureData } from "./useFilesFeature";

export type FileUploadStates = Record<string, FileUploadState>;

export type FileUploadState = {
  cancel: () => Promise<void>;
  data: UploadedFileResult;
  error: { message: string; status: number } | null;
  fileId: Filev2["id"] | undefined;
  isCancelled: boolean;
  loading: boolean;
  timeout: ReturnType<typeof setTimeout> | undefined;
};

export const CANCEL_ERROR = "Cancelled";

const REMOVE_FILE_UPLOAD_SUCCESS_TIMEOUT = 10000;
const REMOVE_FILE_UPLOAD_CANCEL_TIMEOUT = 10000;

export function useUploadFile({
  auctionRequest,
  params,
  setAction,
  setUploadStates,
  uploadStates,
}: {
  auctionRequest?: AuctionRequest;
  params: FileParams;
  setAction: SetAction<FileFeatureData>;
  setUploadStates: Dispatch<SetStateAction<FileUploadStates>>;
  uploadStates: FileUploadStates;
}) {
  const { trackEvent } = useTracking();
  const envContext = useEnvContext();
  const loggedCareseeker = useLoggedCareseeker();
  const loggedCareprovider = useLoggedCareprovider();

  const [createFile] = useCreateFileMutation({ params });
  const [updateFile] = useMutation(UPDATE_FILE);
  const [deleteFile] = useMutation(DELETE_FILE);

  const [createEncryptionContext] = useCreateEncryptionContext();
  const createSealdSession = useSealdContext()?.createSealdSession;

  const [getFilesLazy] = useGetFilesLazyQuery({ params });
  const { refetchEvents } = useAuctionRequestEventsContext();

  // prerender selectable careproviders for share modal
  const [getSelectableCareprovidersLazy] = useGetSelectableCareprovidersLazy();

  const handleUploadFile: FileUploadSubmitHandler = async (
    fileData,
    callback,
  ) => {
    const {
      category,
      category_other,
      content,
      file,
      fileName,
      kis_document_id_string,
    } = fileData;
    const id = `file_${uuid()}_${new Date().getTime()}`;

    try {
      const uploadStartTime = getTime(new Date());

      callback?.();

      const fileState: FileUploadState = {
        cancel: async () => {
          fileState.isCancelled = true;
          setUploadStates((prev) => ({
            ...prev,
            [id]: { ...prev[id], isCancelled: true, loading: false },
          }));
        },
        data: fileData,
        error: null,
        fileId: -1,
        isCancelled: false,
        loading: true,
        timeout: undefined,
      };

      setUploadStates((prev) => {
        return {
          ...prev,
          [id]: fileState,
        };
      });

      const isMessenger = params.includes("auction_request_id");
      const createdById = loggedCareprovider?.id || loggedCareseeker?.id;
      if (!createdById || (loggedCareprovider?.id && loggedCareseeker?.id)) {
        throw new Error(
          "[handleUploadedFile] The createdById must be defined. Either careseeker or careprovider must be defined, but not both!",
        );
      }

      if (fileState.isCancelled) {
        throw new Error(CANCEL_ERROR);
      }
      const { data: createFileData } = await createFile({
        careprovider_id: loggedCareprovider?.id,
        careseeker_id: loggedCareseeker?.id,
        category,
        category_other:
          category === FILE_CATEGORY_AND ? category_other : undefined,
        file_type: file.type,
        kis_document_id_string,
      });

      if (!createFileData?.file) {
        throw new Error("[handleUploadedFile] Couldn't create file!");
      }
      const fileId = createFileData.file.id;

      fileState.fileId = fileId;
      setUploadStates((prev) => {
        const updatedFileState: FileUploadState = {
          ...prev[id],
          fileId,
          cancel: async () => {
            try {
              fileState.isCancelled = true;
              setUploadStates((prev) => ({
                ...prev,
                [id]: {
                  ...prev[id],
                  isCancelled: true,
                  loading: false,
                  timeout: setTimeout(() => {
                    setUploadStates((prev) => {
                      const updatedState = { ...prev };
                      delete updatedState[id];
                      return updatedState;
                    });
                  }, REMOVE_FILE_UPLOAD_CANCEL_TIMEOUT),
                },
              }));
              await deleteFile({
                variables: { params, id: fileId },
                refetchQueries: ["getFiles"],
              });
            } catch (err) {
              console.error(
                `[Cancelled file upload - delete file failed]: Unable to delete file ${fileId} - ${
                  (err as Error).message
                }`,
              );
            }
          },
        };
        return {
          ...prev,
          [id]: updatedFileState,
        };
      });

      const entityUsersDisplayIds = await new Promise<string[]>((resolve) => {
        if (fileState.isCancelled) {
          throw new Error(CANCEL_ERROR);
        }

        const entityUsersDisplayIds = [];

        if (isMessenger) {
          const careproviderId = auctionRequest?.careprovider_id;
          const careseekerId = auctionRequest?.auction?.patient.careseeker?.id;

          if (!careproviderId || !careseekerId) {
            throw new Error(
              "[handleUploadedFile] careprovider and careseeker ID must be defined!",
            );
          }

          entityUsersDisplayIds.push(
            computeSealdDisplayId({
              id: careseekerId,
              type: "careseeker",
              envContext,
            }),
            computeSealdDisplayId({
              id: careproviderId,
              type: "careprovider",
              envContext,
            }),
          );
        } else {
          entityUsersDisplayIds.push(
            computeSealdDisplayId({
              id: createdById,
              type: "careseeker",
              envContext,
            }),
          );
        }

        resolve(entityUsersDisplayIds);
      });

      if (entityUsersDisplayIds?.length < 1) {
        throw new Error(
          "[handleUploadedFile] entityUserDisplayId must be defined!",
        );
      }

      if (fileState.isCancelled) {
        throw new Error(CANCEL_ERROR);
      }

      const loggedGroupDisplayId = loggedCareseeker?.id
        ? computeSealdDisplayId({
            id: loggedCareseeker.id,
            type: "careseeker",
            envContext,
          })
        : loggedCareprovider?.id
        ? computeSealdDisplayId({
            id: loggedCareprovider.id,
            type: "careprovider",
            envContext,
          })
        : null;

      if (!loggedGroupDisplayId) {
        throw new Error(
          "[handleUploadedFile] loggedGroupDisplayId must be defined!",
        );
      }

      const sealdSession = await createSealdSession?.({
        entity: createFileData.file,
        entityType: "file",
        entityUsersDisplayIds,
        createEncryptionContext,
        createSealdAccess: api.crypto.createSealdAccess,
        checkSealdGroupAccess: api.crypto.checkSealdGroupAccess,
        loggedGroupDisplayId,
      });

      if (!sealdSession?.seald_id) {
        throw new Error("[handleUploadedFile] Couldn't create seald session!");
      }

      if (fileState.isCancelled) {
        throw new Error(CANCEL_ERROR);
      }
      const [encryptedFileName, encryptedContent] = await Promise.all(
        [fileName, content].map((message) =>
          encryptForSession({
            message,
            sessionId: sealdSession.seald_id,
            context: `handleUploadFile - ${fileName ? "fileName" : "content"}`,
          }),
        ),
      );

      if (!encryptedFileName || !encryptedContent) {
        throw new Error("[handleUploadedFile] Couldn't encrypt messages!");
      }

      if (fileState.isCancelled) {
        throw new Error(CANCEL_ERROR);
      }

      const { data: updateFileData } = await updateFile({
        variables: {
          id: fileId,
          params,
          input: {
            share_mode: isMessenger
              ? FILE_SHARE_MODE_SELECTED
              : fileData.share_mode ?? FILE_SHARE_MODE_NONE,
            file_name: {
              seald_content: encryptedFileName,
              content: null,
              iv: null,
            },
            file_content: {
              seald_content: encryptedContent,
              content: null,
              iv: null,
            },
          },
        },
        refetchQueries: ["getFiles"],
      });

      if (!updateFileData?.file) {
        throw new Error("[handleUploadedFile] Couldn't update file!");
      }

      if (fileState.isCancelled) {
        throw new Error(CANCEL_ERROR);
      }
      await getFilesLazy();
      refetchEvents?.();

      if (fileState.isCancelled) {
        throw new Error(CANCEL_ERROR);
      }

      // On Success
      const uploadFileDuration = differenceInMilliseconds(
        getTime(new Date()),
        uploadStartTime,
      );
      trackEvent({
        name: TRACK_EVENTS.UPLOAD_FILE_HIS_DURATION,
        duration: uploadFileDuration,
        file_implementation: "HIS",
      });
      setUploadStates((prev) => {
        const updatedFileState: FileUploadState = {
          ...prev[id],
          loading: false,
          timeout: setTimeout(() => {
            setUploadStates((prev) => {
              const updatedState = { ...prev };
              delete updatedState[id];
              return updatedState;
            });
          }, REMOVE_FILE_UPLOAD_SUCCESS_TIMEOUT),
        };
        return {
          ...prev,
          [id]: updatedFileState,
        };
      });

      if (!isMessenger) {
        getSelectableCareprovidersLazy({ id: updateFileData.file.id });
        if (!kis_document_id_string) {
          setAction({
            data: updateFileData.file,
            type: FILE_TABLE_ACTION_SHARE,
          });
        }
      }
    } catch (err) {
      setUploadStates((prev) => {
        if (!prev[id] || prev[id].isCancelled) {
          // file upload state has been deleted or cancelled
          return prev;
        }

        const updatedFileState: FileUploadState = {
          ...prev[id],
          loading: false,
          error: {
            status: (err as any).status || 500,
            message: getErrorMessage(err),
          },
        };
        return {
          ...prev,
          [id]: updatedFileState,
        };
      });

      console.error(err);
    }
  };

  useEffect(() => {
    return () => {
      Object.values(uploadStates).forEach(({ timeout }) => {
        if (timeout) clearTimeout(timeout);
      });
    };
  }, []);

  return { handleUploadFile };
}
